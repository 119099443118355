body {
    margin: 0;
    margin-top: env(safe-area-inset-top);
}

input:is([type='button'], [type='submit'], [type='reset']),
input[type='file']::file-selector-button,
button {
    color: #132122;
}

html,
body,
#root,
.component-app {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

@font-face {
    font-family: 'Objektiv Mk2';
    src: url('fonts/ObjektivMk2-Light.woff') format('woff');
}

@font-face {
    font-family: 'Objektiv Mk2 Bold';
    src: url('fonts/ObjektivMk2-Bold.woff') format('woff');
}

@font-face {
    font-family: 'ABScript';
    src: url('fonts/AbsoluteBeautyScriptBold.ttf') format('truetype');
}

.swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
    opacity: 0.5 !important;
}
